import React, {useRef} from 'react';

import Grid from '@mui/material/Grid';

import Page from '../components/Page';
import Section from '../components/Section';
import Typography from '../components/Typography';
import Button from '../components/Button';
import Card from '../components/Card';
import Quote from '../components/Quote';
import Divider from '../components/Divider';
import FeatureSlider from '../components/FeatureSlider';

import graphicWorkplaces from '../images/graphic_workplaces.svg';
import graphicPrac from '../images/practitioners.svg';
import graphicPatient from '../images/patient.svg';
import innowellAssess from '../images/real_time.png';
import innowellInsight from '../images/insights.png';
import innowellContent from '../images/content.png';
import innowellHabit from '../images/habit.png';

import shield from '../images/icon-shield.svg';
import microscope from '../images/icon-microscope.svg';
import user from '../images/icon-user.svg';
import graph from '../images/icon-graph.svg';

const featureSlides = [
  {
    image: innowellAssess,
    title:'Real-time Assessments',
    content:'Clinical tools and instruments, carefully designed along with mental health experts at the Brain and Mind centre give live, meaningful insights into a user’s mental health and pinpoint key focus areas for care.',
  },
  {
    image: innowellInsight,
    title:'Actionable Insights',
    content:'Individual privacy is protected at every level while giving your workplace deep, actionable insights into their health posture and recommending active tools to engage, support, and empower your people towards better mental health care.',
  },
  {
    image: innowellContent,
    title:'People-First Content',
    content:'A rich library of recommended articles, podcasts, activities, and more individualised for each user and designed to grow healthier self-management and understanding of personal mental health care.',
  },
  {
    image: innowellHabit,
    title:'Proactive Habit Tools',
    content:'Focus areas are targeted to allow users to best improve their self-managed mental health and paired with micro-habit-forming activities designed to untangle bad habits and grow healthier behaviour loops.',
  },
]

const ForWorkplaces = () => {

  const headerRef = useRef();

  return (
    <Page title='For Workplaces' headerRef={headerRef}>
      {/* Hero */}
      <Section>
        <Grid container direction='row-reverse'>
          <Grid item xs={12} md={6}
            sx={{
              textAlign: 'center',
            }}
          >
            <img alt='Graphic for Workplaces' src={graphicWorkplaces}/>
          </Grid>
          <Grid item xs={12} md={6}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography type='hero' preset={1}>Healthy minds, healthy workplaces. </Typography>
            <Typography type='paragraph' preset={2}>
              Research-based and people-centric, Innowell provides real-time metrics for your company’s mental health posture and its people’s needs, building personalised pathways to fitter mental health care and sustainable habits.
            </Typography>
            <Button theme='secondary' onClick={() => headerRef.current.triggerBookNow()}>Book a demo</Button>
          </Grid>
        </Grid>
      </Section>

      <Section>
        <Typography type='heading' preset={2}>The BIG problem (that we're working to fix)</Typography>
        <Typography type='paragraph' preset={2}>
          1 in every 2 Australians will suffer from ill mental health at some stage in their life, and in a 12-month period 21% of Australians will take time off work due to stress, anxiety, depression, or ill mental health.
        </Typography>
        <Typography type='paragraph' preset={2}>
          The estimated economic cost of this problem is $500 million a day, and those figures double when counting indirect costs – loss of productivity, absenteeism, turnover of staff, and its impact on co-workers. When research reveals 91% of workers believe mental health in the workplace is important but only 52% believing their workplace is mentally healthy, we know something needs to be fixed.
        </Typography>
        <Typography type='paragraph' preset={2}>
          Mental health support is no longer a secondary perk. It is a leadership strategy.
        </Typography>
        <Grid container columnSpacing={{sm: 8}}  sx={{mb: 4}}>
          <Grid item xs={12} sm={4}>
            <Card
              sx={{height: '100%'}}
              theme='mint'>
              <Typography type='hero' preset={1} disableMargin>15%</Typography>
              <Divider/>
              <Typography type='paragraph' preset={2}>of Australians experienced high to very-high levels of psychological distress</Typography>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card
              sx={{height: '100%'}}
              theme='mint'>
              <Typography type='hero' preset={1} disableMargin>75%</Typography>
              <Divider/>
              <Typography type='paragraph' preset={2}>cite workplace burnout as the core reason for poor mental health.</Typography>
            </Card>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Card
              sx={{height: '100%'}}
              theme='mint'>
              <Typography type='hero' preset={1} disableMargin>50%</Typography>
              <Divider/>
              <Typography type='paragraph' preset={2}>of people who left jobs cited mental health as the main motivator.</Typography>
            </Card>
          </Grid>
        </Grid>
        <Typography type='heading' preset={2}>Your Solution</Typography>

        <ul>
          <li>
            <Typography type='paragraph' preset={2}>Science-backed tools and pathways that focus on reflection, self-management, and reshaping behaviour loops.</Typography>
          </li>
          <li>
            <Typography type='paragraph' preset={2}>Growing a 'mental health first' workplace culture that doesn’t just improve quality-of-life for employees but works proactively to strengthen community wellbeing and happiness. </Typography>
          </li>

          <li>
            <Typography type='paragraph' preset={2}>Proactive, not reactive tools that support all areas of employee wellbeing, through seven domains of mental health. </Typography>
          </li>

          <li>
            <Typography type='paragraph' preset={2}>Lead a mental health first culture and help your people be well, with Innowell. </Typography>
          </li>
        </ul>
      </Section>

      {/* Administrators/Individuals */}
      <Section theme='primary'>
        <Grid container>
          <Grid item xs={12} md={6}
            sx={{
              textAlign: 'center',
            }}
          >
            <img alt='workplaces graphic' src={graphicPrac}/>
          </Grid>
          <Grid item xs={12} md={6}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography type='heading' preset={2}>Administrators</Typography>
            <Typography type='paragraph' preset={2}>
              Our workplace platform provides detailed, actionable insights and reporting into company mental health posture and employee needs and trends, highlighting risks across multiple dimensions.
            </Typography>
            <Typography type='paragraph' preset={2}>
              Innowell provides the science-based digital tools, instruction, and motivation to support healthier mental fitness throughout your organisation and facilitate exclusive, prioritised content and configurable pathways to drive initiatives in response to our data-based metrics.
            </Typography>
          </Grid>
        </Grid>
      </Section>
      <Section theme='primary'>
        <Grid container>
          <Grid item xs={12} md={6}
            sx={{
              textAlign: 'center',
            }}
          >
            <img alt='workplaces graphic' src={graphicPatient}/>
          </Grid>
          <Grid item xs={12} md={6}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography type='heading' preset={2}>Individuals</Typography>
            <Typography type='paragraph' preset={2}>
              Innowell is a fully configurable platform that puts the research-backed tools and resources in your hands to support your mental health.
            </Typography>
            <Typography type='paragraph' preset={2}>
              Through research backed reflective instruments, a rich library of expert resources, and a personalised pathway of activities that grow healthy habit loops, your mental health care becomes just as integral to quality of life and performance as your physical wellbeing.
            </Typography>
            <Typography type='paragraph' preset={2}>
              We grow a 'mental health first' culture by putting the tools of care back into the hands of your people.
            </Typography>
          </Grid>
        </Grid>
      </Section>

      {/* The Clinical Platform */}
      <Section title='The Innowell Platform'>
        <FeatureSlider slides={featureSlides}/>
      </Section>

      {/* Quote */}
      <Section theme='mint'>
        <Quote content='Imagine if people weren’t at risk of burnout from work, but rather were rejuvenated by the support its leaders gave them.'/>
      </Section>

      {/* Icon Grid */}
      <Section>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Card center>
              <img src={graph} alt='Data-driven icon'/>
              <Typography type='heading' preset={3}>Data-driven</Typography>
              <Typography type='paragraph' preset={2}>Real-time, configurable analysis and deep analytics that give you control over meaningful, actionable reporting.</Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card center>
              <img src={user} alt='Person Centric icon'/>
              <Typography type='heading' preset={3}>About people</Typography>
              <Typography type='paragraph' preset={2}>The tools and are backed by real science but the paths are built for your people and their needs. </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card center>
              <img src={shield} alt='Interoperable icon'/>
              <Typography type='heading' preset={3}>Secure and private</Typography>
              <Typography type='paragraph' preset={2}>We never share data without your wish.</Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card center>
              <img src={microscope} alt='Research led icon'/>
              <Typography type='heading' preset={3}>Research validated</Typography>
              <Typography type='paragraph' preset={2}>More than 10 years of research and over a dozen research trials.</Typography>
            </Card>
          </Grid>
        </Grid>
      </Section>
    </Page>
  )
};

export default ForWorkplaces;
